import { SiteContext } from "@organic-return/foundation-gatsby-plugin-drupal/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { ISiteMenuItem } from "@organic-return/foundation-gatsby-plugin-drupal/src/lib/utils"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: drupalSiteConfiguration {
        rentalSearchPath    
      }
      allRentalBooking(limit: 1 sort:{startDate: ASC}) {
        nodes {
          startDate
        }
      }
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    input: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
  }
  const priceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $200k", value: "200000" },
            { label: "> $400k", value: "400000" },
            { label: "> $600k", value: "600000" },
            { label: "> $800k", value: "800000" },
            { label: "> $1M", value: "1000000" },
            { label: "> $3M", value: "3000000" },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $200k", value: "200000" },
            { label: "< $400k", value: "400000" },
            { label: "< $600k", value: "600000" },
            { label: "< $800k", value: "800000" },
            { label: "< $1M", value: "1000000" },
            { label: "< $3M", value: "3000000" },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }

  const rentalPriceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $1k", value: "1000" },
            { label: "> $2k", value: "2000" },
            { label: "> $3k", value: "3000" },
            { label: "> $5k", value: "5000" },
            { label: "> $7k", value: "7000" },
            { label: "> $10k", value: "10000" },
            { label: "> $15k", value: "15000" },
            { label: "> $20k", value: "20000" },
            { label: "> $30k", value: "30000" },
            { label: "> $40k", value: "40000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
      </>
    )
  }

  const bedroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const bathroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const waterfrontFilter = function (context: ISearchContext) {
    return (
      <Check
        context={context}
        label="Waterfront"
        inputClassName=" bg-transparent"
        param="waterfront"
        className="text-composite-text"
        resolver={(arg: any) => {
          return arg.hasWaterfront
        }}
      />
    )
  }

  const waterviewsFilter = function (context: ISearchContext) {
    return (
      <Check
        context={context}
        label=" Water Views"
        inputClassName=" bg-transparent"
        param="waterviews"
        className="text-composite-text"
        resolver={(arg: any) => {
          return arg.hasWaterViews
        }}
      />
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="minorArea"
          resolver={arg => arg.minorArea}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        {bedroomsFilter(context)}
        {bathroomsFilter(context)}
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="subType"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
        />
        {priceFilters(context)}
        {waterfrontFilter(context)}
        {waterviewsFilter(context)}
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="minorArea"
          resolver={arg => arg.minorArea}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Single Family",
              label: "Single Family",
            },
            {
              value: "Land",
              label: "Land",
            },
            {
              value: "Single Family Detached",
              label: "Single Family Detached",
            },
            {
              value: "Condo/Townhouse",
              label: "Condo/Townhouse",
            },
            {
              value: "Commercial",
              label: "Commercial",
            },
            {
              value: "Multi-Family",
              label: "Multi-Family",
            },
            {
              value: "Land -Single Family Acreage",
              label: "Land -Single Family Acreage",
            },
          ]}
        />
        {priceFilters(context)}
      </>
    )
  }
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="any"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <span className="text-composite-text not-italic bold">{"OR"}</span>
        <Check
          context={context}
          label="Our Properties Only"
          inputClassName=" bg-transparent"
          param="ours"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.exclusive || false
          }}
        />
      </>
    )
  }
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }

  const rentalSearchCheckBoxes = function (context: ISearchContext) {
    return (
      <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
        <label className="col-span-2 text-uppercase">ADDITIONAL FILTERS</label>
        <Check
          context={context}
          label="Air Conditioning"
          inputClassName=" bg-transparent"
          param="air"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "A/C") !== undefined
          }}
        />
        <Check
          context={context}
          label="Waterfront"
          inputClassName=" bg-transparent"
          param="waterfront"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Waterfront") !== undefined
          }}
        />
        <Check
          context={context}
          label="Linens provided"
          inputClassName=" bg-transparent"
          param="linens"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Linens Provided") !== undefined
          }}
        />
        <Check
          context={context}
          label="Pool"
          inputClassName=" bg-transparent"
          param="pool"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Pool") !== undefined
          }}
        />
      </div>)
  }

  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          className="col-span-2"
          showAllEntriesOption={true}
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        {rentalSearchCheckBoxes(context)}
      </>
    )
  }

  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        {<DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        {rentalPriceFilters(context)}
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l1.price || l1.priceHigh || 0) - (l0.price || l0.priceHigh || 0)
              )
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sleeps: High to Low",
            key: "sleepsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sleeps || 0) - (l0.sleeps || 0)
            },
          },
          {
            label: "Sleeps: Low to High",
            key: "sleepsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sleeps || 0) - (l1.sleeps || 0)
            },
          },
        ]}
      />
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl -mt-6 drop-shadow left-0 bg-transparent"
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="22" stroke="white" stroke-width="4" /><path d="M28.1999 10.2L14.3999 24L28.1999 37.8" stroke="white" stroke-width="4" /><path d="M14.9999 23.9999L71.0999 23.9999" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl drop-shadow -mt-6 inline-block right-0 bg-transparent"
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="47.1002" cy="24" r="22" transform="rotate(-180 47.1002 24)" stroke="white" stroke-width="4" /><path d="M42.9003 37.8L56.7003 24L42.9003 10.2" stroke="white" stroke-width="4" /><path d="M56.1003 24.0001L0.000284106 24.0001" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  /**
   * Rental calendar minimum date
   */
  let minDate = new Date()
  if (data.allRentalBooking?.nodes && data.allRentalBooking.nodes.length > 0) {
    minDate = new Date(data.allRentalBooking.nodes[0].startDate)
  }

  const locationOrigin = typeof window !== "undefined" ? window.location.origin : "";
  const siteMenuItems: ISiteMenuItem[] = [
        {
      isFolder: true,
      menuText: "Vacation Rentals",
      children: [
        {
          isFolder: true,
          menuText: "Rentals By Features",
          children: [
            {
              isFolder: false,
              menuText: "Rentals With Pools",
              path: `${locationOrigin}/rental-search/rentals?page=1#filters=%7B"features"%3A%5B"Pool"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Waterfront Rentals",
              path: `${locationOrigin}/rental-search/rentals?page=1#filters=%7B"waterfront"%3A"on"%2C"features"%3A%5B"Waterfront"%5D%7D&sort="priceDESC"&showMap=true`,
            },
            {
              isFolder: false,
              menuText: "Downtown Rentals",
              path: `${locationOrigin}/rental-search/rentals?page=1#filters=%7B"features"%3A%5B"Downtown"%5D%7D&showMap=true&sort="priceDESC"`,
            }
          ]
        },
        {
          isFolder: true,
          menuText: "Search By Town",
          children: [
            {
              isFolder: false,
              menuText: "Edgartown",
              path: `${locationOrigin}/rental-search/rentals/?page=1#filters=%7B"address.city"%3A%5B"Edgartown"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Chilmark",
              path: `${locationOrigin}/rental-search/rentals/?page=1#filters=%7B"address.city"%3A%5B"Chilmark"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Oak Bluffs",
              path: `${locationOrigin}/rental-search/rentals/?page=1#filters=%7B"address.city"%3A%5B"Oak+Bluffs"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Vineyard Haven",
              path: `${locationOrigin}/rental-search/rentals/#page=1&filters=%7B"address.city"%3A%5B"Vineyard+Haven"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "West Tisbury",
              path: `${locationOrigin}/rental-search/rentals/#page=1&filters=%7B"address.city"%3A%5B"West+Tisbury"%5D%7D&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Aquinnah",
              path: `${locationOrigin}/rental-search/rentals/#page=1&filters=%7B"address.city"%3A%5B"Aquinnah"%5D%7D&showMap=true&sort="priceDESC"`,
            },
          ]
        },
        {
          isFolder: false,
          menuText: "Search",
          path: `${locationOrigin}/rental-search/rentals`
        }
      ]
    },
    {
      isFolder: true,
      menuText: "The Island",
      children: [
        {
          isFolder: false,
          menuText: "Events",
          path: "/the-island/marthas-vineyard-event-calendar"
        },
        {
          isFolder: false,
          menuText: "Beaches",
          path: "/the-island/beaches"
        },
        {
          isFolder: false,
          menuText: "Getting Here",
          path: "/the-island/getting-here"
        }
      ]
    },
    {
      isFolder: true,
      menuText: "Sales",
      children: [
        {
          isFolder: false,
          menuText: "Sell With Us",
          path: "https://www.sothebysrealty.com/wallacecosir/eng/sell-with-us"
        },
        {
          isFolder: false,
          menuText: "Buy With Us",
          path: "https://www.sothebysrealty.com/wallacecosir/eng/buy-with-us",
        }
      ]
    },
    {
      isFolder: true,
      menuText: "Home Search",
      children: [
        {
          isFolder: true,
          menuText: "Search",
          children: [
            {
              isFolder: false,
              menuText: "Our Exclusive Listings",
              path: `${locationOrigin}/search#filters=%7B"ours"%3A"on"%7D&showMap=true&page=1&sort="priceDESC"&showMap=true&sort="priceDESC"`,
            },
            {
              isFolder: false,
              menuText: "Residential",
              path: `${locationOrigin}/search#filters=%7B"type"%3A%5B"Single+Family+Residence"%2C"Condominium"%2C"Multi+Family"%5D%7D&sort="priceDESC"&showMap=true&page=1`,
            },
            {
              isFolder: false,
              menuText: "Land",
              path: `${locationOrigin}/search#filters=%7B"subType"%3A"Unimproved+Land"%7D&sort="priceDESC"&showMap=true&page=1`,
            }
          ]
        },
        {
          isFolder: true,
          menuText: "Search by town",
          children: [
            {
              isFolder: false,
              menuText: "Edgartown",
              path: `/home-search/search-by-town/Edgartown-Real-Estate`,
            },
            {
              isFolder: false,
              menuText: "Chilmark",
              path: `/home-search/search-by-town/Chilmark-Real-Estate`,
            },
            {
              isFolder: false,
              menuText: "Oak Bluffs",
              path: `/home-search/search-by-town/Oak-Bluffs-Real-Estate`,
            },
            {
              isFolder: false,
              menuText: "Vineyard Haven",
              path: `/home-search/search-by-town/Vineyard-Haven-Real-Estate`,
            },
            {
              isFolder: false,
              menuText: "West Tisbury",
              path: `/home-search/search-by-town/West-Tisbury-Real-Estate`,
            },
            {
              isFolder: false,
              menuText: "Aquinnah",
              path: `/home-search/search-by-town/Aquinnah-Real-Estate`,
            }
          ]
        },
        {
          isFolder: true,
          menuText: "Lifestyle search",
          children: [
            {
              isFolder: false,
              menuText: "Water Views",
              path: `${locationOrigin}/search#page=1&filters=%7B"waterviews"%3A"on"%7D&showMap=true`,
            },
            {
              isFolder: false,
              menuText: "Waterfront",
              path: `${locationOrigin}/search#page=1&filters=%7B"waterfront"%3A"on"%7D&showMap=true`,
            }
          ]
        }
      ]
    },
    {
      isFolder: true,
      menuText: "About Us",
      children: [
        {
          isFolder: false,
          menuText: "The Rental Process",
          path: "/about-us/rental-process"
        },
        {
          isFolder: false,
          menuText: "Meet Our Team",
          path: "/about-us/rental-team",
        }
      ]
    },
    {
      isFolder: false,
      menuText: "Blog",
      path: "/blog"
    }
  ]

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted uppercase",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true,
            omitAddressParts: ["state", "postal"],
            showClosedTag: true,
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "bg-zinc-200 flex flex-col items-center px-10 pt-5 pb-14 mt-10",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase z-50",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            showClosedTag: true,
          },
          summaryProps: {
            showFeatures: false,
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 pb-15 my-10",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
        },
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          carouselProps: {
            className: "center",
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
          calendarProps: {
            displayRates: true,
            timezone: "America/New_York",
            openToDate: new Date("2025-07-01"),
            minimumDate: minDate,
          }
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          alternateFilters: listingMiddleFilters,
          sort: listingSort,

          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-99",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          secondaryFilters: rentalSearchCheckBoxes,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          headerClassName: "max-w-screen-lg mx-auto",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-xl mx-auto",
          className: "inverted",
          showCount: false,
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.customFields.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          checkboxGroups: {
            label: "I am interested in the following",
            groups: [
              { list: ["Having a broker contact me", "Vacation rentals"] },
              {
                list: [
                  "Edgartown",
                  "Oak Bluffs",
                  "West Tisbury",
                  "Chilmark",
                  "Vineyard Haven",
                  "Aquinnah",
                ],
              },
            ],
          },
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: [
                "Multi Family",
                "Duplex",
                "Triplex",
                "Townhouse",
              ],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"]
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ]
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ]
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        globalListingCarouselProps: {
          slickSettings: {
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
          }
        },
        globalListingCarouselCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: true
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: true,
          vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border0
        },
        agentLayoutProps: {
          carouselProps: {
            slickSettings: {
              prevArrow: <PrevArrow />,
              nextArrow: <NextArrow />
            }
          },
          testimonialsProps: {
            showAttribution: true
          }
        },
        siteMenuItems: siteMenuItems,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}